import anime from 'animejs/lib/anime.es.js';
import gsap from 'gsap';

import { CSSPlugin } from "gsap/CSSPlugin";
gsap.registerPlugin(CSSPlugin);
import { TimelineMax } from 'gsap';
import { Power4 } from 'gsap/all'; 


export function idxFunc() {

  const indexTop = $("#indexTop").length;
  let clickCount = 0;

  if(indexTop){
    var bgContent = document.querySelector(".bgContent");
    var introArea = document.querySelector(".introArea");
    var introTxtArea = document.querySelector(".introTxtArea");
    var mainContentBox = document.querySelector(".mainContentBox");
    var startArea = document.querySelector(".startArea");
    var cloudImg = document.querySelector(".cloudImg");
    var startFlag = false;
    var startBtn = document.querySelector("#startBtn");
    var choiceArea = document.querySelector(".choiceArea");
    const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
    var param = location.search;
    if(param != "?type=replay"){
      wait(0)
      .then(() => {
        introTxtArea.classList.add("isIn")
        return wait(2500)
      }).then(() => {
        mainContentBox.classList.add("isShow")
        introTxtArea.classList.add("isShow")
        return wait(8000)
      }).then(() => {
        introArea.classList.add("hide");
        introTxtArea.classList.remove("isShow")
        return wait(1000)
      }).then(() => {
        introTxtArea.classList.remove("isIn")
        return wait(100)
      }).then(() => {
        introTxtArea.classList.add("hide")
        bgContent.classList.add("isShow");
        animateFadeInFunc();
        return wait(2000)
      }).then(() => {
        animateFunc();
        startArea.classList.add("isIn")
        return wait(1000)
      }).then(() =>{
        startArea.classList.add("isShow")
      })
    } else {
      introArea.classList.add("hide");
      introTxtArea.classList.add("hide")
      bgContent.classList.add("isShow");
      animateFadeInFunc();
      animateFunc();
      mainContentBox.classList.add("isShow");
      startArea.classList.add("hide")
      choiceArea.classList.add("isShow" , "isIn");
    }


      /*-----------------------------------
      背景の雲のアニメーション
      -----------------------------------*/
      function animateFadeInFunc(){
        anime({
          targets:".cloud",
          opacity: 1,
          delay:function(){
            return anime.random(1000,1500);
          },
          duration: 5000,
          fill: 'forwards'
        });
      }
      
      function animateFunc(){
        anime({
          targets:".cloud",
          translateX:function(){
            return anime.random(-100,100);
          },
          translateY:function(){
            return anime.random(-20,20);
          },
          delay:function(){
            return anime.random(0,1000);
          },
          duration: 10000,
          easing: 'linear',
          complete: animateFunc,
        });
      }

      /*-----------------------------------
      スタートボタンをクリックした処理
      -----------------------------------*/


      startBtn.addEventListener('click',function(){
        if(!startFlag){
          startFlag = true;
          startArea.classList.remove("isShow")
          wait(1000)
          .then(() => {
            startArea.classList.remove("isIn")
            return wait(0)
          }).then(() => {
            cloudImg.classList.add("hide")
            startArea.classList.add("hide")
            choiceArea.classList.add("isIn")
            return wait(1000)
          }).then(() => {
            choiceArea.classList.add("isShow")
          })
        }
      });
      
      /*-----------------------------------
      svgの順番をランダムにし、小数点以下を削除
      -----------------------------------*/
      // SVGの各<path>要素を取得
      var paths = document.querySelectorAll('#svgAnime path.st0');
      // NodeListをArrayに変換
      var pathsArray = Array.from(paths);

      // 要素をランダムに並び替え
      pathsArray.sort(function() {
        return 0.5 - Math.random();
      });

      // 新しい順番でSVGに追加
      var svg = document.querySelector('#svgAnime');
      svg.innerHTML = '';
      pathsArray.forEach(function(path) {
        svg.appendChild(path);
      });

      for (var i = 0; i < paths.length; i++) {
          // 現在のパスの座標データを取得
          var pathData = paths[i].getAttribute('d');
          
          // 小数点以下を削除した座標データを作成
          var newPathData = pathData.replace(/\d+\.\d+/g, function(match) {
              return Math.floor(parseFloat(match)); // 小数点以下を切り捨て
          });
          
          // 新しい座標データを設定
          paths[i].setAttribute('d', newPathData);
      }

      /*-----------------------------------
      パラパラのアニメーション処理
      -----------------------------------*/
        var tmax_optionsGlobal = {
          repeat: 0,
        };
    
        CSSPlugin.useSVGTransformAttr = true;
    
        var tl = new TimelineMax(tmax_optionsGlobal),
          path = '#svgAnime path',
          stagger_val = 0.001,
          duration = 3.5;
    
        $.each($(path), function(i, el) {
          tl.set($(this), {
            x: '+=' + getRandom(-1000, 1000),
            y: '+=' + getRandom(-1000, 1000),
            rotation: '+=' + getRandom(-180, 180),
            opacity: 0,
          });
        });
  
        function getRandom(min, max) {
          return Math.random() * (max - min) + min;
        }
  
        var stagger_opts_to = {
          x: 0,
          y: 0,
          opacity: 1,
          rotation: 0,
          ease: Power4.easeInOut
        };

        function svgAnime(){
          tl.staggerTo(path, duration, stagger_opts_to, stagger_val);
        }

      /*-----------------------------------
      おみくじを引く処理
      -----------------------------------*/
      function getRandomPostLink(i) {
        return new Promise(function (resolve, reject) {
          var getUrl = window.location.hostname;
          var domain,siteDomain;
          if(getUrl == 'hny2024.test'){
            domain = 'http://hny2024.test';
            siteDomain = "http://hny2024.test";
          } else if(getUrl == 'localhost'){
            domain = 'http://localhost:3000';
            siteDomain = "http://hny2024.test";
          } else if(getUrl == '100kichi.i-clip.co.jp'){
            domain = 'https://100kichi.i-clip.co.jp';
            siteDomain = "https://100kichi.i-clip.co.jp";
          } 
          var all_data = [];
          $.ajax({
            type: "GET",
            dataType: "json",
            url: domain + "/wp-json/wp/v2/posts?per_page=100&_fields=link,id&categories=" + i,
            success: function (response) {
              all_data = response;
              var randomIndex = Math.floor(Math.random() * all_data.length);
              var randomPost = all_data[randomIndex];
              var relativeUrl = randomPost.link.replace(siteDomain, "");
              resolve(relativeUrl);
            },
            error: function () {
              reject("エラーが発生しました。");
            }
          });
        });
      }

      for (let i = 1; i <= 10; i++) {
        $(`#cate${i}`).on("click", function () {
          if (clickCount == 0) {
            clickCount++;
            document.cookie = "access=accessed; max-age=3600";
            var screenWidth = window.innerWidth;
            if (screenWidth > 767){
              getRandomPostLink(i)
              .then(function (link) {
                wait(0)
                .then(() => {
                  var buttons = document.querySelectorAll(".cateBtn");
                  var secondButton = buttons[1].id
                  buttons.forEach(function (button) {
                    if (button.id !== `cate${i}`) {
                      button.classList.add("notSelectButton");
                    }
                  });
                  if(secondButton == `cate${i}`){
                    var paperAnime = document.querySelector(".paperAnime");
                    paperAnime.classList.add("isShow");
                  }else{
                    buttons.forEach(function (button) {
                      if (button.id == `cate${i}`) {
                        button.classList.add("selectButton");
                      }
                    });
                    var paperAnime = document.querySelector(".paperAnime");
                    paperAnime.classList.add("isShow");
                    return wait(1500)
                  }
                }).then(() => {
                  svgAnime();
                  return wait(4000)
                }).then(() => {
                  window.location.href = link;
                })
              })
              .catch(function (error) {
                alert(error);
              });
            } else { 
              getRandomPostLink(i)
              .then(function (link) {
                wait(0)
                .then(() => {
                  var buttons = document.querySelectorAll(".cateBtn");
                  buttons.forEach(function (button) {
                    if (button.id !== `cate${i}`) {
                      button.classList.add("notSelectButton");
                    } else {
                      button.classList.add("selectButton");
                    }
                  });

                  var paperAnime = document.querySelector(".paperAnime");
                  paperAnime.classList.add("isShow");
                  return wait(1500)
                }).then(() => {
                  svgAnime();
                  return wait(4000)
                }).then(() => {
                  window.location.href = link;
                })
              })
              .catch(function (error) {
                alert(error);
              });
            }
          }
        });
      }


      /*-----------------------------------
      親要素の幅に応じてフォントサイズを変更
      -----------------------------------*/
      function adjustFont(parentsId, childId, factorPc, factorSp) {
        var resizeTimeout;
        var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      
        if (resizeTimeout) {
          clearTimeout(resizeTimeout);
        }
      
        resizeTimeout = setTimeout(function () {
          var parents = document.querySelectorAll(parentsId);
      
          if(screenWidth > 767){
            parents.forEach(function (parent) {
              var parentWidth = parent.offsetWidth;
              var child = parent.querySelector(childId);
              var fontSize = parentWidth / factorPc;
              child.style.fontSize = fontSize + 'px';
            });
          } else {
            parents.forEach(function (parent) {
              var parentWidth = parent.offsetWidth;
              var child = parent.querySelector(childId);
              var fontSize = parentWidth / factorSp;
              child.style.fontSize = fontSize + 'px';
            });
          }
        }, 200);
      }
      
      // 初回のフォントサイズ調整
      adjustFont('#choiceAreaTtlArea', '#choiceAreaTtl', 30 , 18);
      adjustFont('.startArea', '#startBtn', 50 , 23);
      adjustFont('.startArea', '#rightTxt', 50 , 20);
      adjustFont('.startArea', '#leftTxt', 50 , 20);
      adjustFont('.startArea', '#bottomTxt', 50 , 27);
      adjustFont('.introTxtArea', '#siteTtl', 40 , 16);
      adjustFont('.introTxtArea', '#siteDesc', 50 , 23);
      adjustFont('.introTxtArea', '#siteDescNum', 50 , 23);
      
      // ウィンドウのリサイズ時にもフォントサイズを調整
      window.addEventListener('resize', function () {
        adjustFont('#choiceAreaTtlArea', '#choiceAreaTtl', 30 , 18);
        adjustFont('.startArea', '#startBtn', 50 , 23);
        adjustFont('.startArea', '#rightTxt', 50 , 20);
        adjustFont('.startArea', '#leftTxt', 50 , 20);
        adjustFont('.startArea', '#bottomTxt', 50 , 27);
        adjustFont('.introTxtArea', '#siteTtl', 40 , 16);
        adjustFont('.introTxtArea', '#siteDesc', 50 , 23);
        adjustFont('.introTxtArea', '#siteDescNum', 50 , 23);
      });
      
      
  }
}