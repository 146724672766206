export function archiveFunc(){
	const archiveTop = $("#archiveTop").length;

	if(archiveTop){
        //* ----------------------------------------
        //ランダムでカードを表示
        //--------------------------------------- */
        const elements = document.querySelectorAll('.card');
        const shuffledElements = Array.from(elements).sort(() => Math.random() - 0.5);
        
        // Promiseを使って非同期処理をラップする関数
        const fadeElementsIn = (element, index) => {
          return new Promise(resolve => {
            const scaleArea = document.getElementById('scaleArea');
            scaleArea.classList.add('navIn')
            setTimeout(() => {
              element.style.opacity = '1';
              resolve();
            }, index * 30);
          });
        };
        const promises = shuffledElements.map((element, index) => fadeElementsIn(element, index));
        Promise.all(promises).then(() => {
            const myResultCard = document.getElementById('myResultCard');
            if(myResultCard){
                myResultCard.classList.add('finishAnime')
            }
        });


        //* ----------------------------------------
        //ドラッグで要素を移動させる処理
        //--------------------------------------- */
        const targetElement = document.getElementById('targetElement');
        let isDragging = false;
        let offsetX, offsetY,screenWidth,screenHeight;

        function screenChange() {
            screenWidth = window.innerWidth;
            screenHeight = window.innerHeight;
        }
        screenChange();

        window.addEventListener("resize", function () {
            screenChange();
            handleResize();
            updateScaleParameters();
        });
        

        function resetToInitialState() {
            // ここに初期状態に戻す処理を追加する
            // 例: サイズをリセットする、位置をリセットするなど
            currentSize = 1;
            updateSize();
            targetElement.style.left = '0px';
            targetElement.style.top = '0px';
            if (screenWidth > 767) {
                // PCの場合の初期状態に戻す処理
                targetElement.style.width = '190.625vw';
            } else {
                // モバイルの場合の初期状態に戻す処理
                targetElement.style.width = '200vw';
            }

            screenChange(); // 画面サイズを再取得
        }
        
        function handleResize() {
            resetToInitialState();
            screenChange(); // 画面サイズを再取得
        }


        // タッチイベントを処理
        targetElement.addEventListener('touchstart', (e) => {
            isDragging = true;
            const rect = targetElement.getBoundingClientRect();
            offsetX = (e.touches[0].clientX - rect.left) / rect.width;
            offsetY = (e.touches[0].clientY - rect.top) / rect.height;
            targetElement.classList.add('dragging');
        });
        
        document.addEventListener('touchmove', (e) => {
            if (!isDragging) return;
            const rect = targetElement.getBoundingClientRect();
            let x = e.touches[0].clientX - offsetX * rect.width;
            let y = e.touches[0].clientY - offsetY * rect.height;
        
            // 範囲チェック
            x = Math.min(0, Math.max(-(rect.width - screenWidth), x));
            y = Math.min(0, Math.max(-(rect.height - screenHeight), y));
        
            targetElement.style.left = `${x}px`;
            targetElement.style.top = `${y}px`;
        });
        
        document.addEventListener('touchend', () => {
            isDragging = false;
            targetElement.classList.remove('dragging');
        });
        
        // マウスイベントを処理
        targetElement.addEventListener('mousedown', (e) => {
            isDragging = true;
            const rect = targetElement.getBoundingClientRect();
            offsetX = (e.clientX - rect.left) / rect.width;
            offsetY = (e.clientY - rect.top) / rect.height;
            targetElement.classList.add('dragging');
        });
        
        document.addEventListener('mousemove', (e) => {
            if (!isDragging) return;
            const rect = targetElement.getBoundingClientRect();
            let x = e.clientX - offsetX * rect.width;
            let y = e.clientY - offsetY * rect.height;
        
            // 範囲チェック
            x = Math.min(0, Math.max(-(rect.width - screenWidth), x));
            y = Math.min(0, Math.max(-(rect.height - screenHeight), y));

            targetElement.style.left = `${x}px`;
            targetElement.style.top = `${y}px`;
        });
        
        document.addEventListener('mouseup', () => {
            isDragging = false;
            targetElement.classList.remove('dragging');
        });

        //* ----------------------------------------
        //拡大縮小の処理
        //--------------------------------------- */

        const scaleDownBtn = document.getElementById('scaleDownBtn');
        const scaleUpBtn = document.getElementById('scaleUpBtn');
        let minScale, maxScale, baseScale, targetElementWidth;
        targetElementWidth = targetElement.clientWidth
        
        let currentSize = 1;
        const sizeControlBar = document.getElementById('sizeControlBar');
        let startPositionX = 0;
        let isMouseActive = false;


        function updateScaleParameters() {
            if (screenWidth > 767) {
                // PC表示の場合
                if (screenWidth >= screenHeight) {
                    minScale = screenWidth / targetElement.clientWidth;
                    maxScale = 1 + (1 - minScale);
                    baseScale = (maxScale - minScale) / 10;
                } else {
                    minScale = screenHeight / targetElement.clientHeight;
                    maxScale = 1 + (1 - minScale);
                    baseScale = (maxScale - minScale) / 10;
                }
            } else {
                // SP表示の場合
                minScale = screenWidth / targetElement.clientWidth;
                maxScale = 1 + (1 - minScale);
                baseScale = (maxScale - minScale) / 10;
            }
            targetElementWidth = targetElement.clientWidth
        }
        updateScaleParameters();
        
        scaleDownBtn.addEventListener('click', function () {
            changeSize(-baseScale);
        })
        
        scaleUpBtn.addEventListener('click', function () {
            changeSize(baseScale);
        })
        
        function startEvent(e) {
            if (e.touches) {
                startPositionX = e.touches[0].clientX;
            } else {
                startPositionX = e.clientX;
            }
        
            isMouseActive = true;
        
            document.addEventListener('touchmove', handleTouchMove);
            document.addEventListener('touchend', handleTouchEnd);
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }
        
        sizeControlBar.addEventListener('mousedown', startEvent);
        sizeControlBar.addEventListener('touchstart', startEvent);
        
        function handleMove(e, clientX) {
            if (isMouseActive) {
                const delta = clientX - startPositionX;
                startPositionX = clientX;
        
                const barMaxWidth = document.querySelector(".sizeControlBarWrap").clientWidth;
                const stepWidth = baseScale;
                const stepSize = stepWidth * barMaxWidth;
        
                const scaleChange = delta / stepSize * baseScale; 

                currentSize = Math.max(minScale, Math.min(maxScale, currentSize + scaleChange)); 

                updateSize();
            }
        }
        
        function handleMouseMove(e) {
            handleMove(e, e.clientX);
        }
        
        function handleTouchMove(e) {
            handleMove(e, e.touches[0].clientX);
        }
        
        function handleEnd() {
            isMouseActive = false;
        
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
        
        function handleMouseUp() {
            handleEnd();
        }
        
        function handleTouchEnd() {
            handleEnd();
        }
        
        function changeSize(delta) {
            currentSize = Math.max(minScale, Math.min(maxScale, currentSize + delta));
            updateSize();
        }
        
        function updateSize() {
            const targetElement = document.getElementById('targetElement');
            const eleWidth = targetElementWidth * currentSize
            console.log(targetElementWidth)
            targetElement.style.width = `${eleWidth}px`;

            updateBarPosition();

            const eleLeftVal = parseInt(targetElement.style.left) * -1
            const rePositionX = eleWidth - screenWidth

            if(eleLeftVal > rePositionX){
                let x = eleWidth - screenWidth
                targetElement.style.left = `-${x}px`
            }

            const eleTopVal = parseInt(targetElement.style.top) * -1
            const eleHeight = targetElement.clientHeight
            const rePositionY = eleHeight - screenHeight

            if(eleTopVal > rePositionY * .9){
                let y = eleHeight - screenHeight
                targetElement.style.top = `-${y}px`
            }

            adjustFontSize();
        }

        function updateBarPosition() {
            const barWidth = (currentSize - 0.5) / 1 * 100; // スケールに応じてバーの幅を計算
            if (currentSize == minScale) {
                sizeControlBar.style.width = `0%`;
            } else if (currentSize == maxScale) {
                sizeControlBar.style.width = `100%`;
            } else {
                sizeControlBar.style.width = `${barWidth}%`;
            }
        
            const iconWidth = document.querySelector("#sizeControlBar .icon").clientWidth / 10;
            const minWidth = iconWidth;
            sizeControlBar.style.minWidth = `${minWidth}px`;

            adjustFontSize();
        }

        //* ----------------------------------------
        //読み込み時自分の結果への移動
        //--------------------------------------- */

        const myResult = document.getElementById('myResultCard');

        if(myResult){
            var myResultCard = document.getElementById('myResultCard');
            var targetElementInner = myResultCard.closest('.targetElementInner');
            var targetElementInnerRect = targetElementInner.getBoundingClientRect();

            // myResultCard要素の座標を取得
            var myResultCardRect = myResultCard.getBoundingClientRect();

            // 位置情報の取得
            var myResultCardValX = (myResultCardRect.left + myResultCardRect.width / 2) - targetElementInnerRect.left;
            var myResultCardValY = (myResultCardRect.top + myResultCardRect.height / 2) - targetElementInnerRect.top;

            //動かせる範囲
            const maxValX = targetElement.clientWidth - screenWidth;
            const maxValY = targetElement.clientHeight - screenHeight;

            if(maxValX <= myResultCardValX && maxValY <= myResultCardValY){
                //left topとも動かせる範囲をオーバー
                //topを中央に移動できるかチェック
                if(myResultCardValY - screenHeight / 2 <= maxValY && myResultCardValY - screenHeight / 2 >= 0){
                    var myResultCardValYNew = myResultCardValY - screenHeight / 2
                    targetElement.style.top = `-${myResultCardValYNew}px`;
                } else {
                    targetElement.style.top = `-${maxValY}px`;
                }

                //leftを中央に移動できるかチェック
                if(myResultCardValX - screenWidth / 2 <= maxValX && myResultCardValX - screenWidth / 2 >= 0){
                    var myResultCardValXNew = myResultCardValX - screenWidth / 2
                    targetElement.style.left = `-${myResultCardValXNew}px`;
                } else {
                    targetElement.style.left = `-${maxValX}px`;
                }

            } else if(maxValX <= myResultCardValX && maxValY > myResultCardValY){
                 //left が動かせる範囲をオーバー
                targetElement.style.left = `-${maxValX}px`;

                //topを中央に移動できるかチェック
                if(myResultCardValY - screenHeight / 2 <= maxValY && myResultCardValY - screenHeight / 2 >= 0){
                    var myResultCardValYNew = myResultCardValY - screenHeight / 2
                    targetElement.style.top = `-${myResultCardValYNew}px`;
                } else {
                    targetElement.style.top = `0px`;
                }

                //leftを中央に移動できるかチェック
                if(myResultCardValX - screenWidth / 2 <= maxValX && myResultCardValX - screenWidth / 2 >= 0){
                    var myResultCardValXNew = myResultCardValX - screenWidth / 2
                    targetElement.style.left = `-${myResultCardValXNew}px`;
                } else {
                    targetElement.style.left = `-${maxValX}px`;
                }
                
            } else if(maxValX > myResultCardValX && maxValY <= myResultCardValY){
                //TOP が動かせる範囲をオーバー
                //topを中央に移動できるかチェック
                if(myResultCardValY - screenHeight / 2 <= maxValY && myResultCardValY - screenHeight / 2 >= 0){
                    var myResultCardValYNew = myResultCardValY - screenHeight / 2
                    targetElement.style.top = `-${myResultCardValYNew}px`;
                } else {
                    targetElement.style.top = `-${maxValY}px`;
                }

                //leftを中央に移動できるかチェック
                if(myResultCardValX - screenWidth / 2 <= maxValX && myResultCardValX - screenWidth / 2 >= 0){
                    var myResultCardValXNew = myResultCardValX - screenWidth / 2
                    targetElement.style.left = `-${myResultCardValXNew}px`;
                } else {
                    targetElement.style.left = `0px`;
                }

            } else {
                //どちらも動かせる範囲内
                //topを中央に移動できるかチェック
                if(myResultCardValY - screenHeight / 2 <= maxValY && myResultCardValY - screenHeight / 2 >= 0){
                    var myResultCardValYNew = myResultCardValY - screenHeight / 2
                    targetElement.style.top = `-${myResultCardValYNew}px`;
                } else {
                    targetElement.style.top = `0px`;
                }

                //leftを中央に移動できるかチェック
                if(myResultCardValX - screenWidth / 2 <= maxValX && myResultCardValX - screenWidth / 2 >= 0){
                    var myResultCardValXNew = myResultCardValX - screenWidth / 2
                    targetElement.style.left = `-${myResultCardValXNew}px`;
                } else {
                    targetElement.style.left = `0px`;
                }
            }
        } else {
            targetElement.style.left = `0px`;
            targetElement.style.top = `0px`;
        }


        //* ----------------------------------------
        //画像のドラッグを禁止する処理
        //--------------------------------------- */
        document.onselectstart = function(){ return false; };
        document.onmousedown = function(){ return false; };
        document.body.onselectstart = "return false;"
        document.body.onmousedown = "return false;"


        function adjustFontSize() {
            var parents = document.querySelectorAll('.viewCount');
      
            parents.forEach(function(parent) {
              var parentWidth = parent.offsetWidth;
              var child = parent.querySelector('.viewCountNum');
              var fontSize = parentWidth / 13; // 例として親要素の幅の1/10をフォントサイズに設定
      
              child.style.fontSize = fontSize + 'px';
            });
        }

        function spText(){
            var resizeTimeout;
			var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			var rePlayLinkBtnTxt = document.querySelector("#rePlayLinkBtnTxt");
			if (resizeTimeout) {
				clearTimeout(resizeTimeout);
			}
		
			resizeTimeout = setTimeout(function () {
			if(screenWidth > 767){
                if(rePlayLinkBtnTxt){
                    rePlayLinkBtnTxt.innerText = "おみくじを引き直す";
                }
			} else {
                if(rePlayLinkBtnTxt){
                    rePlayLinkBtnTxt.innerText = "引き直す";
                }
			}
			}, 200);
        }
      
          // 初回のフォントサイズ調整
          adjustFontSize();
          spText();
      
          // ウィンドウのリサイズ時にもフォントサイズを調整
          window.addEventListener('resize', function(){
            adjustFontSize();
            spText();
          });
	}
}