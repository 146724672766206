import Stickyfill from 'stickyfilljs/dist/stickyfill.min.js';

export function mainContentHeightFunc() {

  const indexTop = $("#indexTop").length;
  const singleTop = $("#singleTop").length;
      //メインコンテンツのサイズを取得
  var resizeTimeout;
  var mainContentBox = document.querySelector(".mainContentBox");

  function handleResize() {
    
    var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }

    // 一定時間後に処理を実行
    resizeTimeout = setTimeout(function () {
      // PC画面幅の56.25%が正規のコンテンツ幅
      var contentWidth = screenWidth * 0.5625;

      // SP画面幅の140%が正規のコンテンツ高さ
      var contentSpHeight = screenWidth * 1.1533;

      //上記のサイズより画面幅が短い場合の画面幅の計算
      var contentSpWidth = (screenHeight * 0.8) / 1.1533;

      if(screenWidth > 767){
        if (screenHeight >= contentWidth) {
          mainContentBox.style.height = contentWidth + "px";
          mainContentBox.style.width = contentWidth + "px";
        } else {
          mainContentBox.style.height = screenHeight + "px";
          mainContentBox.style.width = screenHeight + "px";
        }
      } else{
        if (contentSpHeight <= screenHeight) {
          mainContentBox.style.height = contentSpHeight + "px";
          mainContentBox.style.width = screenWidth + "px";
        } else {
          mainContentBox.style.height = screenHeight * 0.8 + "px";
          mainContentBox.style.width = contentSpWidth + "px";
        }
      }
    }, 200);
  }

  if(mainContentBox){
    handleResize();
  }
  //スマホ時の検索窓の高さを除いた値を取得
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener("resize", function(){
    if(mainContentBox){
      handleResize();
    }
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  //スクロールを禁止する関数
  function disableScroll(event) {
    event.preventDefault();
  }
  document.addEventListener('touchmove', disableScroll, { passive: false });
  document.addEventListener('mousewheel', disableScroll, { passive: false });

}



export function observeFunc() {
  const clientHeight = document.documentElement.clientHeight;
  const header = document.querySelector('#header');
  let option = {
    root: document.querySelector("#observerArea"),
    // 上下100px、左右20px手前で発火
    rootMargin: "100px 20px",
    // 交差領域が50%変化するたびに発火
    threshold: [0, 0.5, 1.0]
  };
  let observer = new IntersectionObserver((changes) => {
    for (let change of changes) {
      let rect = change.target.getBoundingClientRect();
      let h = (0 < rect.top && rect.top < clientHeight) // 対象の上端は表示領域に入っている
        ||
        (0 < rect.bottom && rect.bottom < clientHeight) // 対象の下端は表示領域に入っている
        ||
        (0 > rect.top && rect.bottom > clientHeight); // 上端下端も表示されてないがその間が表示されている
      if (h) {
        header.classList.remove('scrolled');
      } else {
        header.classList.add('scrolled');
      }
    }
  }, option);
  let target = document.querySelector('.xyz');
  observer.observe(target);
}

export function fadeInFunc() {
  const options = {
    root: null,
    threshold: 0,
  };

  const callback = (function (changes) {
    for (let change of changes) {
      let target = change.target;
      if (change.isIntersecting) {
        target.classList.add('active');
      }
    }
  });

  const observer = new IntersectionObserver(callback, options);

  const targets = document.querySelectorAll('.fadeIn')
  for (let target of targets) {
    observer.observe(target)
  }
}

/** スクロール一時禁止 **/
let scroll_control = function (e) {
  e.preventDefault();
}
export function no_scroll() {
  document.addEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.addEventListener("touchmove", scroll_control, {
    passive: false
  });
}
export function return_scroll() {
  document.removeEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.removeEventListener('touchmove', scroll_control, {
    passive: false
  });
}

export function sticyFunc() {
  // position: stickyがブラウザで使えるかチェックするための関数
  function detectSticky() {
    const div = document.createElement('div');
    div.style.position = 'sticky';
    return div.style.position.indexOf('sticky') !== -1;
  }
  // もしブラウザでposition: stickyが使えなければ、callStickyState関数を呼び出す
  if (!detectSticky()) {
    let jsSideNav = $('#pageTop');
    Stickyfill.add(jsSideNav);
  }
}

export function waitFunc() {
  const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
  wait(1000)
    .then(() => {
      console.log(1)
      return wait(1000)
    }).then(() => {
      console.log(2)
      return wait(1000)
    }).then(() => {
      console.log(3)
    })
}

export function loadingFunc() {
  $('#loaderBg').fadeOut(600, function() {
    $('#loader').delay(200).fadeOut();
  });
}

export function percentLoadingFunc() {
  // ローディング画面
  var images = document.getElementsByTagName('img'); // ページ内の画像取得
  var percent = document.getElementById('percent-text'); // パーセントのテキスト部分
  var gauge = document.getElementById('gauge'); // ゲージ
  var loading = document.getElementById('loading'); // ローディング要素
  var count = 0;
  var gaugeMax = 400; // ゲージの幅指定
  var current;
  var imgNum = images.length;

  // 画像の読み込み
  for (var i = 0; i < images.length; i++) {
    var img = new Image(); // 画像の作成
    // 画像読み込み完了したとき
    img.onload = function () {
      count += 1;
    }
    // 画像読み込み失敗したとき
    img.onerror = function () {
      count += 1;
    }
    img.src = images[i].src; // 画像にsrcを指定して読み込み開始
  };

  // ローディング処理
  var nowLoading = setInterval(function () {
    // 現在の読み込み具合のパーセントを取得
    current = Math.floor(count / imgNum * 100);
    // パーセント表示の書き換え
    percent.innerHTML = current;
    percent.style.transform = 'translateX(' + current + '%)';

    // ゲージの変更
    gauge.style.width = Math.floor(gaugeMax / 100 * current) + 'px';
    // 全て読み込んだ時

    if (count >= imgNum || count == imgNum) {
      // ローディング要素の非表示
      $('#loading').delay(200).fadeOut(2000);
      //loading.style.display = 'none';
      // ローディングの終了
      clearInterval(nowLoading);
    }
  }, 100);
}