import gsap from 'gsap';
import html2canvas from 'html2canvas'

import { CSSPlugin } from "gsap/CSSPlugin";
gsap.registerPlugin(CSSPlugin);
import { TimelineMax } from 'gsap';
import { Power4 } from 'gsap/all'; 
export function singleFunc(){
	const singleTop = $("#singleTop").length;

	if(singleTop){
		var paperAnime = document.querySelector(".paperAnime");
		var mainContentBox = document.querySelector(".mainContentBox");
		var replayAnnounceArea = document.querySelector(".replayAnnounceArea");
		const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
		wait(0)
		.then(() =>{
			/*-----------------------------------
			svgの順番をランダムにし、小数点以下を削除
			-----------------------------------*/
			// SVGの各<path>要素を取得
			var paths = document.querySelectorAll('#svgAnime path.st0');
			// NodeListをArrayに変換
			var pathsArray = Array.from(paths);

			// 要素をランダムに並び替え
			pathsArray.sort(function() {
				return 0.5 - Math.random();
			});

			// 新しい順番でSVGに追加
			var svg = document.querySelector('#svgAnime');
			svg.innerHTML = '';
			pathsArray.forEach(function(path) {
				svg.appendChild(path);
			});

			for (var i = 0; i < paths.length; i++) {
				// 現在のパスの座標データを取得
				var pathData = paths[i].getAttribute('d');
				
				// 小数点以下を削除した座標データを作成
				var newPathData = pathData.replace(/\d+\.\d+/g, function(match) {
					return Math.floor(parseFloat(match)); // 小数点以下を切り捨て
				});
				
				// 新しい座標データを設定
				paths[i].setAttribute('d', newPathData);
			}

		}).then(() => {
			var tmax_optionsGlobal = {
				repeat: 0,
			  };
			  
			  CSSPlugin.useSVGTransformAttr = true;
			  
			  var tl = new TimelineMax(tmax_optionsGlobal),
				path = '#svgAnime path',
				stagger_val = 0.001,
				duration = 3.5;
			  
			  $.each($(path), function(i, el) {
				var stagger_opts_to = {
				  ease: Power4.easeInOut,
				  x: '+=' + getRandom(-1000, 1000),
				  y: '+=' + getRandom(-1000, 1000),
				  rotation: '+=' + getRandom(-180, 180),
				  opacity: 0,
				};
				
				tl.to($(this), duration, stagger_opts_to, stagger_val);
			  });
			  
			  function getRandom(min, max) {
				return Math.random() * (max - min) + min;
			  }
		  return wait(3500)
		}).then(() => {
			paperAnime.classList.remove("isShow");
			mainContentBox.classList.add("isShow");
			replayAnnounceArea.classList.add("slideIn")
		})

		/*-----------------------------------
		おみくじ結果画像をダウンロード
		-----------------------------------*/
		const downloadBtn = document.getElementById('downloadBtn');
		downloadBtn.addEventListener('click',function(){
			const ua = navigator.userAgent.toLowerCase()
			if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
				// SP向けの処理
				const downloadImg = document.querySelector("#downloadArea img")
				var imgPath = downloadImg.getAttribute('src')
				window.open(imgPath , '_blank');
			} else {
				html2image('#downloadArea');
			}
		});

		function html2image(html) {
			const FNAME_SAVE = 'omikuji';
			var capture = document.querySelector(html);
			html2canvas(capture, {useCORS: true,scale: 2}).then(canvas => {
			  var base64 = canvas.toDataURL('image/png');
			  $('#download').attr('href', base64);
			  $('#download').attr('download', FNAME_SAVE);
			  $('#download')[0].click();
			});
		}


		/*-----------------------------------
		親要素の幅に応じてフォントサイズを変更
		-----------------------------------*/
		function singleAdjustFont(parentsId, childId, factorPc, factorSp) {
			var resizeTimeout;
			var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		
			if (resizeTimeout) {
				clearTimeout(resizeTimeout);
			}
		
			resizeTimeout = setTimeout(function () {
			var parents = document.querySelectorAll(parentsId);
		
			if(screenWidth > 767){
				parents.forEach(function (parent) {
				var parentWidth = parent.offsetWidth;
				var child = parent.querySelector(childId);
				var fontSize = parentWidth / factorPc;
				child.style.fontSize = fontSize + 'px';
				});
			} else {
				parents.forEach(function (parent) {
				var parentWidth = parent.offsetWidth;
				var child = parent.querySelector(childId);
				var fontSize = parentWidth / factorSp;
				child.style.fontSize = fontSize + 'px';
				});
			}
			}, 200);
		}

		function spTxtFunc(){
			var resizeTimeout;
			var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			var downloadBtnTxt = document.getElementById("downloadBtnTxt");
			var archiveLinkTxt = document.getElementById("archiveLinkTxt");
			var rePlayBtnTxt = document.getElementById("rePlayBtnTxt");
			if (resizeTimeout) {
				clearTimeout(resizeTimeout);
			}
		
			resizeTimeout = setTimeout(function () {
		
			if(screenWidth > 767){
				downloadBtnTxt.innerText = "おみくじを持ち帰る";
				archiveLinkTxt.innerText = "みんなの百吉を見る";
				rePlayBtnTxt.innerText = "おみくじを引き直す";
			} else {
				downloadBtnTxt.innerText = "持ち帰る";
				archiveLinkTxt.innerText = "みんなの百吉";
				rePlayBtnTxt.innerText = "引き直す";
			}
			}, 200);
		}
		
		// 初回のフォントサイズ調整
		singleAdjustFont('#btnArea', '#downloadBtnTxt', 52 , 25);
		singleAdjustFont('#btnArea', '#archiveLinkTxt', 52 , 25);
		singleAdjustFont('#btnArea', '#rePlayBtnTxt', 52 , 25);
		singleAdjustFont('#replayAnnounceArea', '#replayAnnounceTxt', 15 , 8.5);

		spTxtFunc();
		
		// ウィンドウのリサイズ時にもフォントサイズを調整
		window.addEventListener('resize', function () {
			singleAdjustFont('#btnArea', '#downloadBtnTxt', 52 , 25);
			singleAdjustFont('#btnArea', '#archiveLinkTxt', 52 , 25);
			singleAdjustFont('#btnArea', '#rePlayBtnTxt', 52 , 25);
			singleAdjustFont('#replayAnnounceArea', '#replayAnnounceTxt', 15 , 8.5);
			spTxtFunc();
		});
		  
	}
}